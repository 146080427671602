.message-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 13px;
  z-index: 999;
  display: flex;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
}
.message-container > div {
  margin-top: 10px;
}
